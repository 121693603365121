import styled from 'styled-components'

import Footer from '~/components/Footer'
import SideNav from '~/components/SideNav'
import TopNav from '~/components/TopNav'
import { AppDimensions } from '~/constants'

const AppGrid = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* single column on small screens */
    grid-template-columns: 1fr;
  }
`

const AppPageComponent = styled.div`
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background: ${({ theme }) => `${theme.colors.gradient.primary}`};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* additional padding so footer clears floating nav */
    padding-bottom: 110px;
  }
`

const AppSideBar = styled.div`
  grid-area: sidebar;
  overflow: hidden;
  z-index: 100;
  position: sticky;
  top: ${AppDimensions.navBarHeight};
  height: calc(100vh - ${AppDimensions.navBarHeight});
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* relocate nav to floating button */
    position: fixed;
    top: unset;
    height: unset;
    bottom: 0px;
    /* add space for drop-shadow on child element */
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colorTokens.border['border-subdued']};
  }
`

const AppTopNav = styled.div`
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: ${AppDimensions.navBarHeight};
`

const AppContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const ComponentContainer = styled.div`
  flex: 1 0 auto;
`

const AppLayout: React.FunctionComponent = ({ children }) => {
  return (
    <AppGrid>
      <AppTopNav>
        <TopNav />
      </AppTopNav>
      <AppContent>
        <AppSideBar>
          <SideNav />
        </AppSideBar>
        <AppPageComponent>
          <ComponentContainer>{children}</ComponentContainer>
          <Footer />
        </AppPageComponent>
      </AppContent>
    </AppGrid>
  )
}

export default AppLayout
