import { BodyText2 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

interface PageDateProps {
  dateString: string
}

const PageDate: React.FC<PageDateProps> = ({ dateString }) => {
  return (
    <DateContainer>
      <BodyText2>{dateString}</BodyText2>
    </DateContainer>
  )
}

export default PageDate

const DateContainer = styled.div`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
  margin-bottom: 8px;
`
